.uploader-droparea {
    border: dashed 1px var(--gray-500);
    border-radius: var(--border-radius-s);
    height: 212px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    position: relative;

    &.darker {
        background-color: var(--gray-100);
    }

    .progressbar {
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
    }
}
