.code {
    border-radius: var(--border-radius-s);
    background-color: var(--gray-200);
    padding: var(--spacing-m) var(--spacing-xxxl) var(--spacing-m) var(--spacing-m);
    margin: var(--spacing-xs) 0;
    display: block;
    position: relative;
    font-size: var(--text-sm);
    line-height: 140%;
    white-space: pre-wrap;

    .action {
        position: absolute;
        top: var(--spacing-xs);
        right: var(--spacing-xs);
    }

    &.inline {
        display: inline-block;
        padding: var(--spacing-2xs) var(--spacing-xxxl) var(--spacing-2xs) var(--spacing-xs);
        margin: 0;

        .action {
            top: 0;
            right: var(--spacing-2xs);
        }
    }

    pre code.hljs {
        background-color: transparent;
        padding: 0;
    }

    &.expandable {
        padding-right: 68px;
    }
}
