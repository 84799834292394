.notifications-snippet-popover {
    .header {
        padding: var(--spacing-2xs) var(--spacing-m);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--spacing-m);

        h4 {
            line-height: 170%;
        }
    }
}
