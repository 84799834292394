.loader {
    text-align: center;
    margin-top: var(--spacing-xxl);
    margin-bottom: var(--spacing-m);

    h4 {
        margin-top: var(--spacing-s);
    }
}

.progressbar-wrapper {
    position: relative;

    .bar {
        height: 14px;
    }

    .value {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 10px;
    }
}
