@layout-header-background: #33303e;
@sider-trigger-background: darken(@layout-header-background, 10%);
@primary-color: #00c9d5;

#shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:root {
    // Sizing
    --spacing-3xs: 2px;
    --spacing-2xs: 4px;
    --spacing-xs: 8px;
    --spacing-m: 16px;
    --spacing-l: 20px;
    --spacing-xl: 24px;
    --spacing-xxl: 32px; // TODO: fix these values
    --spacing-xxxl: 40px; // TODO: fix these values
    --spacing-2xl: 48px;
    --spacing-3xl: 96px;
    --spacing-4xl: 176px;

    --size-m: 20px;
    --size-xl: 48px;

    --size-layout-2xs: 224px;
    --size-layout-xs: 384px;
    --size-layout-s: 512px;

    --size-box-m: 288px;

    --text-2sx: 10px;
    --text-sx: 12px;
    --text-sm: 14px;
    --text-m: 16px;
    --text-xm: 18px;
    --text-2xm: 20px;
    --text-l: 24px;
    --text-4xl: 36px;

    --border-radius-xs: 2px;
    --border-radius-s: 4px;
    --border-radius-m: 8px;

    // colors
    --gray-100: rgb(243 244 246);
    --gray-200: rgb(229 231 235);
    --gray-300: rgb(209 213 219);
    --gray-500: rgb(107 114 128);
    --gray-600: rgb(73, 82, 99); // picked by eye
    --gray-700: rgb(55 65 81);
    --gray-800: rgb(31 41 55);
    --gray-900: rgb(17 24 39);

    --white: #fff;
    --whitish: #d1d1f0; // TODO: is it part of gray scale?

    --color-primary: #0397a0;

    // light = 100, dark = 800
    --color-gray-light: rgb(243 244 246);
    --color-orange-light: rgb(255 237 213);
    --color-sky-light: rgb(224 242 254);
    --color-red-light: rgb(254 226 226);
    --color-darkred-light: rgb(255, 189, 189);
    --color-green-light: rgb(220 252 231);
    --color-yellow-light: rgb(251, 255, 213);

    --color-red-lighter: #ffb8b8;
    // --color-red-lightest: #fff3f3;

    --color-gray-dark: rgb(31 41 55);
    --color-orange-dark: rgb(154 52 18);
    --color-sky-dark: rgb(7 89 133);
    --color-red-dark: rgb(153 27 27);
    --color-darkred-dark: rgb(113, 24, 24);
    --color-green-dark: rgb(22 101 52);
    --color-yellow-dark: rgb(158, 152, 38);

    --color-background-light: #efefef;
    --color-background-lighter: #d9d8e3;
    --color-background-darker: rgb(158, 165, 181);
    --color-background-dark: #2d2b37;

    --color-greengray-light: #e4ebee;
    --color-greengray-light2: #d9e5e9;
}

#colors {
    &.gray {
        color: var(--color-gray-dark);
    }
    &.orange {
        color: var(--color-orange-dark);
    }
    &.sky {
        color: var(--color-sky-dark);
    }
    &.red {
        color: var(--color-red-dark);
    }
    &.darkred {
        color: var(--color-darkred-dark);
    }
    &.green {
        color: var(--color-green-dark);
    }
    &.yellow {
        color: var(--color-yellow-dark);
    }
}

#colors-bg {
    &.gray {
        background-color: var(--color-gray-light);
    }
    &.orange {
        background-color: var(--color-orange-light);
    }
    &.sky {
        background-color: var(--color-sky-light);
    }
    &.red {
        background-color: var(--color-red-light);
    }
    &.darkred {
        background-color: var(--color-darkred-light);
    }
    &.green {
        background-color: var(--color-green-light);
    }
    &.yellow {
        background-color: var(--color-yellow-light);
    }
}

/*
 This could be defined in MUI.createTheme.
 But for some reasons it doesn't apply to 100% of the project scope, and only to components inside of ThemeProvider context.
*/
html,
body {
    font-family: 'Helvetica', 'Arial', sans-serif;
    margin: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}

a {
    color: @primary-color;
    // font-size: 14px;
    cursor: pointer;
    text-decoration: inherit;
}

h6 {
    color: black;
}

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}
