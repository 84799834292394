.snippet-error {
    text-align: center;
    margin: 200px 20px 200px 20px;

    .title {
        font-size: 100px;
        font-weight: bold;
        color: var(--color-background-dark);
    }

    .logo-wrapper {
        text-align: center;
        background-color: var(--color-background-dark);
        border-radius: 25px;
        padding: 1px 15px;
        display: inline-block;
        transform: rotate(90deg);
        margin-bottom: 10px;

        .logo {
            float: none;
        }
    }

    .message {
        margin-top: var(--spacing-xl);
        font-size: var(--text-l);
        color: var(--gray-600);
    }

    .cta {
        display: inline-block;
        padding-top: var(--spacing-m);
    }
}
