.text-center {
    text-align: center;
}

// TODO: check if we can apply everywhere
.h1 {
    font-weight: 400;
    font-size: var(--spacing-xxl);
    line-height: var(140%);
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    margin-top: var(--spacing-m);
    margin-bottom: var(--spacing-xl);

    &.compact {
        margin-top: var(--spacing-xs);
        margin-bottom: var(--spacing-xs);
    }
}

.h2 {
    font-weight: 400;
    font-size: var(--text-l);
    line-height: var(140%);
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-l);
}

.h3 {
    font-weight: bold;
    font-size: var(--text-2xm);
    line-height: var(140%);
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    margin-top: var(--spacing-xxl);
    margin-bottom: var(--spacing-l);
    align-items: baseline;

    .small {
        color: var(--gray-500);
        font-size: 12px;
        font-weight: normal;
    }
}

.p {
    line-height: 150%;
    margin: var(--spacing-xs) 0;
}

ul,
ol {
    &.list {
        li {
            margin: var(--spacing-xs) 0;
        }
    }
}

.logo {
    background: url('./../images/logo.png') center no-repeat;
    width: 48px;
    height: 48px;
    background-size: 100%;
    float: left;
}

a:hover {
    text-decoration: underline;
}

.center {
    text-align: center;
}

.text-lg {
    font-size: var(--text-xm);
    line-height: 150%;
}

.space-b-m {
    margin-bottom: var(--spacing-m);
}

.space-y {
    margin-top: var(--spacing-m);
    margin-bottom: var(--spacing-m);
}

.space-y-xs {
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-xs);
}

.icon-m {
    width: var(--size-m);
    height: var(--size-m);
}

.text-gray-500 {
    color: var(--gray-500);
}
.text-gray-700 {
    color: var(--gray-700);
}
.text-gray-800 {
    color: var(--gray-800);
}
.text-gray-900 {
    color: var(--gray-900);
}

.text-sx {
    font-size: var(--text-sx);
    line-height: 140%;
}
.text-sm {
    font-size: var(--text-sm);
    line-height: 140%;
}
.text-m {
    font-size: var(--text-m);
    line-height: 140%;
}
.text-xm {
    font-size: var(--text-xm);
    line-height: 140%;
}
.text-l {
    font-size: var(--text-l);
    line-height: 140%;
}

.absolute {
    position: absolute;
}

// This is fix for Tabs with widee badge
.tab-badge-wide {
    .MuiBadge-root {
        .MuiBadge-standard {
            right: 25px;
            font-size: var(--text-2sx);
            font-weight: bold;
        }
    }
}

.text-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grow {
    flex-grow: 1;
}

.hr {
    border-top: 1px solid var(--gray-200);
}
