.section-empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: var(--spacing-4xl);
    color: var(--gray-700);
    font-size: var(--text-sm);
    line-height: 140%;

    h1 {
        font-size: var(--text-4xl);
        line-height: 120%;
        font-weight: bold;
        color: var(--gray-900);
    }

    p {
        margin-top: var(--spacing-2xs);
    }

    .actions {
        display: flex;
        gap: var(--spacing-m);
        margin: var(--spacing-l) 0;
        align-items: center;
    }
}

.action-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    margin: var(--spacing-l) 0;

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        gap: var(--spacing-m);
        padding: var(--spacing-m);
        border: 1px solid var(--gray-300);
        border-radius: var(--border-radius-s);
        max-width: 900px;
        font-size: var(--text-m);

        .content-rows-s {
            flex-grow: 1;
        }

        p {
            font-size: var(--text-sm);
        }

        button {
            flex-shrink: 0;
            width: 200px;
        }
    }
}
