.form-row {
    display: flex;
    gap: var(--spacing-m);

    + * {
        margin-top: var(--spacing-m);
    }

    &.small > div {
        width: 200px;
    }

    &.mid > div {
        width: calc(200px * 2 + var(--spacing-m));
    }

    &.large > div {
        width: calc(200px * 3 + 2 * var(--spacing-m));
    }
}
