.layout-sidebar-wrapper {
    .layout-sidebar-header {
        padding: 0 var(--spacing-xs);
        background-color: var(--color-greengray-light2);
        height: 48px;
        display: block;
        display: flex;
        align-items: center;
        gap: var(--spacing-2xs);

        a {
            display: flex;
            align-items: center;
            gap: var(--spacing-2xs);
            position: relative;
            color: #000;
            height: 100%;
            padding: var(--spacing-xs);

            &:hover {
                text-decoration: none;
                background-color: #c4cfd3;
                border-radius: var(--border-radius-s);
            }
        }

        span {
            color: var(--gray-500);
        }

        :first-child {
            font-weight: bold;
        }

        > :last-child {
            padding: var(--spacing-xs);
        }
    }
}

.layout-sidebar {
    background-color: var(--color-background-light);
    min-height: 100vh;

    &.MuiGrid-container {
        flex-wrap: nowrap;
    }

    .layout-sidebar-sections {
        width: 200px;
        background-color: var(--color-greengray-light2);
    }

    .sep {
        margin: var(--spacing-xs) 0;
        border-top: 1px solid var(--color-background-darker);
    }
}

.layout-sidebar-item {
    color: var(--color-background-dark);

    &:hover {
        color: #000;
        background-color: #c4cfd3;
        border-radius: var(--border-radius-s);
    }

    .MuiListItemIcon-root {
        color: #7c8a99;
    }

    .chip {
        background-color: var(--color-primary);
        font-size: var(--text-sx);
        height: 22px;
    }

    .active {
        padding-left: var(--spacing-l);
        color: #000;

        &::before {
            content: '';
            width: 8px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: var(--color-primary);
            border-radius: 0 var(--border-radius-s) var(--border-radius-s) 0;
        }
    }
}
