@import 'theme.less';

.alert {
    margin: var(--spacing-xs);

    &.highligh {
        #shadow();
        border-width: 1px;
    }

    .details {
        margin-top: var(--spacing-xs);
    }
}

.disclaimer {
    font-size: var(--text-sm);
    line-height: 140%;
    text-align: center;
    margin-top: var(--spacing-xs);
}

.center-block {
    display: flex;
    justify-content: center;
}
