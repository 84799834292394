.tests-charts {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xxxl);

    .chart {
        width: calc(50% - 20px);
        flex-grow: 1;

        h3 {
            margin-top: var(--spacing-l);
            margin-left: var(--spacing-l);
            margin-bottom: 0;
        }

        .echarts-for-react {
            width: 100%;
        }
    }
}

.link-light {
    display: inline-block;
    font-size: var(--text-sm);
    color: var(--gray-500);

    &:hover {
        color: var(--color-primary);
        text-decoration: none;
    }
}
