.layout-menu {
    min-height: 100vh;
    background-color: var(--color-background-light);

    &.MuiGrid-container {
        flex-wrap: nowrap;
    }

    .layout-menu-sections {
        background-color: var(--color-background-dark);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80px;

        > :nth-child(1) {
            position: sticky;
            top: 0px;
        }

        > :nth-child(2) {
            position: sticky;
            bottom: 0px;
        }

        .logo-wrapper {
            width: 100%;
            text-align: center;
            display: block;
            height: 48px;

            .logo {
                float: none;
                display: inline-block;
            }
        }

        .MuiList-root {
            padding-top: 0;
        }
    }
}

.layout-menu-item {
    .active,
    &:hover {
        background: #555;
        color: #fff;
        border-radius: 1;
        border-radius: var(--border-radius-s);
    }

    .button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 58px;
        padding: var(--spacing-xs);

        .icon {
            min-width: 20px;
            color: var(--gray-100);
        }

        .text {
            display: flex;
            flex-direction: column;
            flex-grow: 0;

            span {
                font-size: 10px;
                color: var(--whitish);
            }
        }
    }
}
