@import '../theme.less';

.tag-status {
    display: flex;
    gap: var(--spacing-2xs);
    align-items: center;
    border-radius: var(--border-radius-m);
    font-size: var(--text-sx);
    line-height: 140%;
    padding: var(--spacing-2xs) var(--spacing-xs);

    #colors();
    #colors-bg();
}

.icon-status {
    #colors();

    &.bigger svg {
        width: 28px;
        height: 28px;
    }
}
