@import 'theme.less';

.content {
    background: #fff;
    padding: 20px;
    border-radius: 2px;
}

.content-big {
    // display: flex;
    // flex-direction: column;
    // align-items: stretch;
    margin: auto;

    @media (min-width: 1280px) {
        width: 1000px;
    }
}

.content-plain {
    padding: var(--spacing-m);

    h1 {
        line-height: 130%;
        font-weight: normal;
        color: var(--gray-900);
        text-align: center;
        margin: var(--spacing-m) 0;
    }
}

.content-cards {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
}

.card {
    padding: var(--spacing-m);
}

.card-error {
    // &,
    // &.MuiPaper-root {
    //     background-color: var(--color-red-lightest);
    // }

    .section-header {
        background-color: var(--color-red-lighter);
    }
}

.content-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-m);
    margin-bottom: var(--spacing-m);

    .box {
        display: block;
        width: var(--size-box-m);
        margin-bottom: var(--spacing-m);
    }
}

.content-rows {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);

    &.center {
        align-items: center;
    }
}

.content-rows-s {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);

    &.center {
        align-items: center;
    }
}

.content-cols {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-m);
    align-items: center;
}

.content-cols-s {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-xs);
    align-items: center;
}

.content-sides {
    display: flex;
    justify-content: space-between;
}

.content-center {
    display: flex;
    justify-content: center;
}

.content-centered {
    display: flex;
    align-items: center;
}

.content-padded {
    padding: var(--spacing-m);
}

.text-fragments {
    display: flex;
    align-items: center;
    gap: var(--spacing-2xs);
}

.layout-unauthorized {
    padding: var(--spacing-3xl) 0;

    .header {
        margin-bottom: var(--spacing-2xl);

        img {
            height: var(--size-xl);
            margin: auto;
        }
    }

    .body {
        background-color: var(--white);
        padding: var(--spacing-xxl) var(--spacing-m);
        border-radius: var(--border-radius-m);
        #shadow();

        @media (min-width: 640px) {
            margin-left: auto;
            margin-right: auto;
            // width: 100%;
            max-width: var(--size-layout-xs);
        }

        h1 {
            font-size: var(--text-l);
            line-height: 130%;
            font-weight: bold;
            color: var(--gray-900);
            text-align: center;
            margin: var(--spacing-m) 0;
        }
    }
}

.notifications-badge {
    position: relative;
    left: 10px;
}

.section-block {
    padding: var(--spacing-m);
    border-bottom: 1px solid var(--gray-200);

    h3 {
        font-weight: normal;
        margin-top: var(--spacing-l);
        margin-bottom: var(--spacing-m);

        &:first-child {
            margin-top: var(--spacing-xs);
        }
    }
}

.section-header {
    padding: var(--spacing-m);
    border-bottom: 1px solid var(--gray-200);
    background-color: var(--color-greengray-light);

    h2 {
        margin: 0;
        line-height: 100%;
    }
}

.section-actions {
    padding: var(--spacing-m);
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-m);

    &.sides {
        justify-content: space-between;
    }
}

.footer {
    text-align: center;
    line-height: 150%;
    margin-top: var(--spacing-m);
    white-space: pre-line;
}

.nav-link {
    span,
    p {
        // color: var(--whitish);
        color: rgba(0, 0, 0, 0.8);
    }
}

.modal-content {
    padding: var(--spacing-m);
}

// TODO: move to comp
.menu-header {
    border: 1px solid red;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table {
    .MuiTableCell-root {
        vertical-align: top;
    }
}

.tabs .MuiButtonBase-root {
    padding-bottom: 5px;

    &:not(.Mui-selected):hover {
        color: var(--gray-900);
    }

    // &.Mui-selected:hover {
    //     color: var(--color-green-dark);
    // }
}

.list-item {
    display: flex;
    white-space: nowrap;
    // align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 6px 20px;
    color: #000;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--gray-100);
    }

    &:is(a):hover {
        color: #000;
        background-color: #fafafa;
        text-decoration: none;
    }

    .text {
        flex-basis: '40%';
        flex-grow: 1;
        padding-left: var(--spacing-m);
        line-height: 150%;

        &.scroll {
            overflow-y: scroll;
        }
    }

    .secondary {
        font-size: var(--text-sm);
        color: var(--gray-500);
    }

    .ternary {
        font-size: var(--text-sm);
        color: var(--gray-800);
    }

    a {
        color: black;
        display: inline-block;

        &:hover {
            color: var(--color-primary);
            text-decoration: none;

            .secondary {
                color: var(--color-primary);
            }
        }
    }

    .li {
        padding-left: var(--spacing-m);
    }

    .w30 {
        width: 30%;
    }

    .w50 {
        width: 50%;
    }

    .valign {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    .subitem {
        width: 100%;
        padding: 6px 6px;
        display: flex;
        white-space: nowrap;
        display: flex;
        gap: 6px;

        &:is(a):hover {
            color: #000;
            background-color: #fafafa;
            text-decoration: none;

            .secondary {
                color: var(--gray-800);
            }
        }

        .text {
            padding-left: 0;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid var(--gray-100);
        }

        .sections {
            display: flex;
        }
    }
}
