@import '../theme.less';

.api-widget-wrapper:hover {
    text-decoration: none;
}

.api-widget {
    &:hover {
        #shadow-lg();
    }

    .header {
        display: flex;
        gap: var(--spacing-xs);
        align-items: center;
        padding: var(--spacing-xs) var(--spacing-m);
        border-bottom: 1px solid var(--gray-100);

        .sub,
        .main {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .main {
            font-size: var(--text-m);
            line-height: 150%;
        }

        .sub {
            font-size: var(--text-sx);
            color: var(--gray-500);
            line-height: 150%;
        }
    }

    .chart {
        padding: var(--spacing-m);
        border-bottom: 1px solid var(--gray-100);
    }

    .count {
        padding: var(--spacing-xs) var(--spacing-m);
    }
}
