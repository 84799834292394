@import '../../static/styles/theme.less';

.text-preformatted {
    background-color: var(--gray-100);
    font-size: var(--text-sx);
    color: var(--gray-500);
    border: 1px solid var(--gray-500);
    padding: var(--spacing-3xs) var(--spacing-2xs);
    border-radius: var(--border-radius-xs);
}
