.step {
    margin: var(--spacing-xxxl);

    @media (min-width: 640px) {
        padding-left: var(--spacing-xxl);
        padding-right: var(--spacing-xxl);
    }

    h3 {
        display: inline;
        padding-left: var(--spacing-m);
    }

    p {
        padding-left: var(--spacing-xxxl);
        margin: var(--spacing-xs) 0;
        font-size: var(--text-sm);
        line-height: 160%;
    }
}
