@import '../../static/styles/theme.less';

.section-headline {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-m);

    h1 {
        font-weight: 400;
        font-size: var(--text-l);
        line-height: var(140%);
        display: flex;
        gap: var(--spacing-xs);
        align-items: center;
    }

    .sub {
        line-height: 150%;
        color: var(--gray-500);
        font-size: var(--text-sm);
    }

    a:not(.MuiButtonBase-root) {
        color: var(--gray-500);
    }

    .main {
        display: flex;
        gap: var(--spacing-m);

        > div {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
        }
    }

    .avatar {
        display: flex;
        align-items: center;

        .placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            font-weight: bold;
            background-color: var(--gray-300);
        }
    }
}
